import {
  defineStore,
  storeToRefs,
} from 'pinia';
import {
  INFO_MESSAGE_PAGE_ROUTE,
  INFO_MESSAGE_PAGE_PARAMS,
} from '~/constants/infoMessages';
import { CASH_TYPES } from '~/constants/cash';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import { SESSION_STORAGE } from '~/constants/storedPropertiesNames';
import { useAuthStore } from '~/stores/auth';
import type {
  CashStoreState,
  CashTypes,
} from '~/types/stores/cash';

export const useCashStore = defineStore({
  id: 'cash',
  state: (): CashStoreState => ({
    showCashModal: false,
    cashType: undefined,
  }),
  getters: {
    getShowCashModal: (state) => state.showCashModal,
    getCashType: (state) => state.cashType,
  },
  actions: {
    toggleCashModal() {
      this.showCashModal = !this.showCashModal;
    },
    setCashType(type: CashTypes) {
      this.cashType = type;
      const router = useRouter();

      window.history.pushState({}, '', decodeURIComponent(
        CASH_TYPES.includes(type as string)
          ? router.resolve({ query: { [REDIRECTION_ROUTES_QUERIES.cashier]: type } }).fullPath
          : router.resolve({ query: {} }).fullPath,
      ));

    },
    closeCashModal() {
      this.toggleCashModal();
      this.setCashType(undefined);
    },
    activateCash() {
      const router = useRouter();
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      if (getUserData.value.blockLite) {
        router.push(useLocalePrefixForHref(`${INFO_MESSAGE_PAGE_ROUTE}/${INFO_MESSAGE_PAGE_PARAMS.blockLite}`));
      } else if (!getUserData.value.profileFilled) {
        sessionStorage.setItem(SESSION_STORAGE.openCashModal, 'true');
        router.push(useLocalePrefixForHref('/cabinet/personal-data'));
      } else {
        this.toggleCashModal();
      }
    },
  },
});
